/*! {!project_name!} v0.2.1 */
/**
 * CSS Stylesheet for: {!project_name!} v0.2.1
 *
 * Author: {!dev_alias!} @ {!dev_agency!}
 *
 * TOC:
 * 
 * CSS Custom properties
 * Plugins style's override
 * * Swiper
 * * Magicssian
 * Base
 * Layout
 * * Swiper
 * * Header
 * * Sections
 * * Footer
 * Media queries
 * 
 * @todo Add each module/section needed
 * 
 */

/* CSS Custom Properties */
@import '../plugins/magicssian/_project_variables.css';


/* Plugins style's override */
/* * Swiper */
.swiper-container .swiper-button-prev {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='28' viewBox='0 0 14.1 28'%3E%3Cpolygon points='5.1 14 14.1 23.1 14.1 28 0 14 14.1 0 14.1 4.9 ' fill='%23BE7B45'/%3E%3C/svg%3E");
  left: 50px;
}
.swiper-container .swiper-button-next {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='28' viewBox='0 0 14.1 28'%3E%3Cpolygon points='9.1 14 0.1 4.9 0.1 0 14.1 14 0.1 28 0.1 23.1 ' fill='%23BE7B45'/%3E%3C/svg%3E");
  right: 50px;
}
.swiper-container .swiper-button-prev, 
.swiper-container .swiper-button-next {
  outline: 0;
}
.swiper-pagination-fraction, 
.swiper-pagination-custom, 
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 50px;
}
.swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 100%;
  background: rgba(255, 255, 255, .3);
  border: 2px solid #fff;
  opacity: 1;
}
.swiper-pagination-bullet-active:after {
  display: block;
  content: '';
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 100%;
  margin: 2px auto;
}
/* * Magicsian */
.grid.gutter-row > .column {
  padding: 0 var(--rhytm-unit);
}
/* Base */
section {
  margin-top: 0;
  margin-bottom: 0;
}
.container.boxed {
  padding-left: 50px;
  padding-right: 50px;
}
fieldset {
  border: 0;
  padding: 0;
}
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  text-transform: uppercase;
  line-height: normal;
}
h1, .h1 {
  font-size: 3rem;
}
h2, .h2 {
  font-size: 2.5rem;
}
h3, .h3 {
  font-size: 1.75rem;
}
h1.medium, h2.medium, h3.medium {
  font-weight: 400;
}
ul, ol, p {
  line-height: 1.75rem;
  letter-spacing: .06rem;
}
/* Layout */

/* * Header */
header {
  position: fixed;
  z-index: 9999;
  width: 100%;
	height: 170px;
  background-color: rgba(0, 4, 19, .85);
}
body.opciones-de-credito header {
  background-color: rgba(0, 4, 19, 1);
}
.header-logo {
  width: 200px;
  height: auto;
}
#contact-info {
  height: 55px;
  border-bottom: 1px solid #163053;
  line-height: 56px;
  color: var(--complementary-color-2);
}
#contact-info .email {
  margin-right: 24px; 
  margin-left: 24px;
}
#contact-info img.icon {
  width: 18px;
  height: auto;
  margin-left: 24px;
  vertical-align: middle;
}
a.main:link, a.main:visited {
  display: inline-block;
  height: 100px;
  width: auto;
  text-transform: uppercase;
  margin-left: 48px;
  border-bottom-width: 5px;
  line-height: 100px;
}
a.main:active, a.main:hover, 
a.main.active {
  color: #fff;
  border-bottom: 5px solid var(--complementary-color-1);
}
header .navigation.main {
  padding-top: 15px;
}
header .navigation.sub {
  position: absolute;
  left: calc((var(--container-boxed-width) - 100vw) / 2);
  width: 100vw;
  height: 90px;
  background-color: #1f3e71;
  line-height: 90px;
}
a.sub:link, a.sub:visited {
  margin-left: 48px;
  color: #c8c8c8;
}
a.sub:active, a.sub:hover, 
a.sub.active {
  color: #fff;
}
/* * Swiper */
.swiper-container {
  width: 100%;
  height: 740px;
}
body.opciones-de-credito .swiper-container {
    margin-top: 170px;
  height: 580px;

}
.swiper-container.home {
  margin-top: 0;
  height: 1000px;
}
.swiper-slide .grid {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 100;
}
.swiper-slide .column, 
.swiper-slide .heading {
  color: #fff;
}
.swiper-slide .content {
  margin-top: 80px;
  overflow: visible;
}
.swiper-slide .content.opciones-de-credito {
  margin-top: 180px;
}
/* * Sections */
#shortcut-nosotros {
  padding-top: 80px;
  padding-bottom: 100px;
}
#shortcut-opciones-de-credito {
  padding-top: 80px;
  padding-bottom: 100px;
  background-color: var(--neutral-color);
}
#shortcut-opciones-de-credito .column {
  min-height: 640px;
  padding: 50px 20px 20px 20px;
  border-top: 50px solid rgba(190, 123, 69, .6);
  background-color: var(--base-color);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
}
#shortcut-opciones-de-credito .heading {
  line-height: normal;
  color: #fff;
  padding: 0 10px;
}
.column.credito-simple {
  background-image: url('../img/background-shortcut-credito-simple.jpg');
}
.column.credito-cuenta-corriente {
  background-image: url('../img/background-shortcut-credito-cuenta-corriente.jpg');
}
.column.credito-refaccionario {
  background-image: url('../img/background-shortcut-credito-refaccionario.jpg');
}
.column.habilitacion-y-avio {
  background-image: url('../img/background-shortcut-habilitacion-y-avio.jpg');
}
.column.fideicomiso-de-garantia {
  background-image: url('../img/background-shortcut-fideicomiso-de-garantia.jpg');
}
#shortcut-opciones-de-credito .column.active {
  -moz-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  -o-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(100%);
  filter: gray;
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
}
.nosotros {
  padding-top: 40px;
  padding-bottom: 100px;
}
.valores {
  background: transparent url('../img/image-nosotros.jpg') center no-repeat;
  background-size: cover;
  margin-bottom: 100px;
}
.valores .content {
  padding: 80px 40px 80px 0;
}
/* Opciones de credito */
article.opciones-de-credito {
  padding-top: 90px;
  padding-bottom: 100px;
}
.requisitos-de-credito {
  background-color: #1f3e71;
  color: #fff;
  padding-top: 90px;
  padding-bottom: 90px;
}
.requisitos-de-credito .heading {
  color: #fff;
}
.requisitos-de-credito ul li {
  margin-bottom: 20px;
}
/* Noticias */
.posts-list {
  padding-top: 90px;
  padding-bottom: 90px;
}
.post-thumb {
  height: 250px;
}
.post-excerpt .date {
  margin-bottom: 24px;
}
/* Contacto */
#contacto {
  padding-top: 80px;
  padding-bottom: 80px;
  background: var(--base-color) url('../img/background-contacto.jpg') center no-repeat;
  background-size: cover;
  color: #fff;
}
.contacto #contacto {
  background-image: none;
}
#asociaciones {
  padding-top: 80px;
  padding-bottom: 80px;
}
#asociaciones .logo {
  margin-right: 80px;
}
.aviso-de-privacidad {
  padding-top: 40px;
  padding-bottom: 100px;
}
/* * Footer */
footer {
	height: 150px;
	background: var(--base-color);
  padding-top: 60px;
}
a.suite100 {   
  width: 19px;
  height: 19px;
  display: block;
  background: transparent url('../img/logo-suite100.svg');
  background-position: left center;
  background-repeat: no-repeat;
}
a.suite100:hover, a.suite100:active {
    width: 70px;
}

/* Media queries */
@media (max-width: 1440px) {
  header .navigation.sub {
    left: 0;
  }
}
@media (max-width: 1280px) {
  .swiper-slide .content {
    flex: 0 0 66.66%;
  }
  #shortcut-opciones-de-credito .heading {
    font-size: 1.25rem;
    font-weight: 600;
  }
}
@media (max-width: 1140px) {
  a.sub:link, a.sub:visited {
    margin-left: 32px;
  }
}
@media (max-width: 1024px) {
  .swiper-container, 
  .swiper-container.home {
    height: 700px;
  }
  .container.boxed {
    padding-left: 24px;
    padding-right: 24px;
  }
  #shortcut-opciones-de-credito .icon {
    width: 50%;
    height: auto;
  }
}
@media (max-width: 980px) {
  header .container {
    height: auto;
  }
  .header-logo-wrapper {
    position: relative;
    z-index: 9999;
  }
  .navigation label {
    margin: 20px 0 0 auto;
  }
  .navigation.slide-l .menu {
    background-color: rgba(0, 4, 19, .95);
    left: -100vw;
    width: 100vw;
    padding-top: 180px;
    padding-left: 20px;
    text-align: left;
  }
  a.main:link, a.main:visited {
    display: block;
    height: 40px;
    line-height: 40px;
    margin-bottom: 10px;
  }
  a.main:link, a.main:visited {
    margin-left: 0;
  }
  a.main:link, a.main:hover, a.main:active, a.main.active {
    border-bottom-width: 2px;
  }
  header .navigation.sub {
    position: relative;
    background-color: transparent;
    text-align: left;
    width: 100%;
    height: auto;
  }
  a.sub:link, a.sub:visited {
    display: block;
    margin-left: 0;
    margin-bottom: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #shortcut-opciones-de-credito .column {
    padding: 20px 10px
  }
}
@media (max-width: 780px) {
  .swiper-slide .content {
    flex: 0 0 33.33%;
    width: 80%;
  }
  footer {
    padding-top: 20px;
  }
  footer p {
    margin-top: 0;
    text-align: left;
    line-height: normal;
  }
}
@media (max-width: 640px) {
  .social-icons {
    display: none;
  }
  h1 {
    font-size: 2.5rem;
  }
  .swiper-container {
    height: 600px;
  }
  .swiper-container .swiper-button-next {
    right: 10px;
  }
  .swiper-container .swiper-button-prev {
    left: 10px;
  }
}
@media (max-width: 480px) {
  .container.boxed {
    padding-left: 12px;
    padding-right: 12px;
  }
  h1 {
    font-size: 2rem;
  }
  .swiper-slide .content {
    width: 100%;
  }
  .swiper-container .swiper-button-next, 
  .swiper-container .swiper-button-prev {
    display: none;
  }
  #asociaciones .logo {
    margin-right: 40px;
    margin-bottom: 40px;
  }
}